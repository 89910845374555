























import {
  defineComponent,
  PropType,
} from '@nuxtjs/composition-api';
import {
  SfModal,
} from '@storefront-ui/vue';
import { useUiState } from '~/composables';
import SlipPaymentComponent from '~/modules/checkout/components/payments/SlipPaymentComponent.vue';
import { ItauBoletoResponse } from '~/server/payments/types/BoletoTypes';

export default defineComponent({
  name: 'LoginModal',
  components: {
    SfModal,
    SlipPaymentComponent,
  },
  props: {
    slipData: {
      type: Object as PropType<ItauBoletoResponse>,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { isBankSlipModalOpen, toggleBankSlipModal } = useUiState();

    return {
      isBankSlipModalOpen,
      toggleBankSlipModal,
    };
  },
});
