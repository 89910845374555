


























































import {
  defineComponent,
  onMounted,
  ref,
} from '@nuxtjs/composition-api';
import { SfPrice } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import JetUiButton from '~/components/JetUi/atoms/JetUiButton/JetUiButton.vue';
import Warning from '~/components/JetUi/atoms/Warning/Warning.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import PixService from '~/server/payments/PixService';

export default defineComponent({
  name: 'PixPaymentComponent',
  components: {
    JetUiButton,
    SvgImage,
    SfPrice,
    Warning,
    SkeletonLoader,
  },
  props: {
    txid: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const emv = ref(null);
    const imageBase64 = ref(null);

    onMounted(async () => {
      if (!props.txid) {
        return;
      }
      const pixQrCode = await PixService.emitirQrCode(props.txid);
      emv.value = pixQrCode.emv;
      imageBase64.value = pixQrCode.imagem_base64;
    });

    const copyToClipboard = (text: string) => {
      navigator.clipboard.writeText(text);
    };

    return {
      copyToClipboard,
      emv,
      imageBase64,
    };
  },
});
