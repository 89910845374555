






























































import {
  SfMenuItem, SfList, SfBar,
} from '@storefront-ui/vue';

import {
  defineComponent,
  useContext,
  useRouter,
  useRoute,
  computed,
  useFetch,
  ref,
} from '@nuxtjs/composition-api';
import PixModal from '~/modules/checkout/pages/Checkout/JetCheckout/JetCheckoutModals/PixModal.vue';
import BankSlipModal from '~/modules/checkout/pages/Checkout/JetCheckout/JetCheckoutModals/BankSlipModal.vue';
import { useUiState } from '~/composables';
import { useUser } from '../../composables/useUser';
import { useSidebarLinkGroups } from './useSidebarLinkGroups';

export default defineComponent({
  name: 'MyAccount',
  components: {
    SfBar,
    SfList,
    SfMenuItem,
    PixModal,
    BankSlipModal,
  },
  middleware: 'is-authenticated',
  setup() {
    const { togglePixModal, toggleBankSlipModal } = useUiState();
    const route = useRoute();
    const router = useRouter();
    const { i18n, localeRoute } = useContext();
    const { user, load: loadUser } = useUser();

    useFetch(async () => {
      if (user.value === null) {
        await loadUser();
      }
    });

    const { sidebarLinkGroups, logoutUser } = useSidebarLinkGroups();

    const isOnSubpage = computed(() => route.value.matched.length > 1);
    const goToTopLevelRoute = () => router.push(localeRoute({ name: 'customer' }));
    const title = computed(() => i18n.t(route.value.matched.at(-1)?.meta.titleLabel as string));

    const txid = ref('');
    const price = ref('');
    const orderId = ref('');
    const slipData = ref(null);

    const makePayment = (data) => {
      txid.value = data.txid ?? null;
      price.value = data.price ?? null;
      orderId.value = data.orderId ?? null;
      slipData.value = data.slipData ?? null;
      if (txid.value && price.value) {
        togglePixModal();
      } else if (slipData.value) {
        toggleBankSlipModal();
      }
    };

    /**
     * #tab-id: handler-name
     */
    const handlers = {
      'log-out': logoutUser,
    };

    const getHandler = (id: string) => handlers[id] ?? {};

    return {
      sidebarLinkGroups,
      title,
      isOnSubpage,
      goToTopLevelRoute,
      logoutUser,
      getHandler,
      makePayment,
      txid,
      price,
      orderId,
      slipData,
    };
  },
  head() {
    return {
      title: this.$t('My Account').toString(),

    };
  },
});
