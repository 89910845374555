




















import { computed, defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Warning',
  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'warning',
      validator: (value: string) => ['warning', 'danger'].includes(value),
    },
  },
  setup(props) {
    const modalClosed = ref(false);
    const closeAlert = () => {
      modalClosed.value = true;
    };

    const typeClass = computed(() => `alert--${props.type}`);
    const iconColor = computed(() => (props.type === 'danger' ? '#D9534F' : '#F7C752'));

    return {
      modalClosed,
      closeAlert,
      typeClass,
      iconColor,
    };
  },
});
