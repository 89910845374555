























import {
  defineComponent,
} from '@nuxtjs/composition-api';
import {
  SfModal,
} from '@storefront-ui/vue';
import { useUiState } from '~/composables';
import PixPaymentComponent from '~/modules/checkout/components/payments/PixPaymentComponent.vue';

export default defineComponent({
  name: 'LoginModal',
  components: {
    SfModal,
    PixPaymentComponent,
  },
  props: {
    txid: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { isPixModalOpen, togglePixModal } = useUiState();

    return {
      isPixModalOpen,
      togglePixModal,
    };
  },
});
